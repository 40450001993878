import request from '@/utils/request'
import { param2Obj } from '@/utils'

// 首页
export function getInfo(data) {
  return request({
    url: '/store/detail',
    method: 'post',
    data: param2Obj(data)
  })
}

export function statisticsShop(data) {
  return request({
    url: '/seller/statistics/store/detail',
    method: 'post',
    data: param2Obj(data)
  })
}

export function statisticsOrder(data) {
  return request({
    url: '/seller/statistics/order/status',
    method: 'post',
		data: param2Obj(data)
  })
}

export function applyVerifyCode(data) {
  return request({
    url: '/store/apply/verifyCode',
    method: 'get',
		data: param2Obj(data)
  })
}

export function applyStepFirst(data) {
  return request({
    url: '/store/apply/step_1',
    method: 'post',
		data: param2Obj(data)
  })
}

export function applyFinish(data) {
  return request({
    url: '/store/apply/finish',
    method: 'post',
    data: param2Obj(data)
  })
}
