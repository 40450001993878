<template>
    <div :id="id" :class="className" :style="{ height: height, width: width }" />
</template>

<script>
import * as echarts from 'echarts'
import resize from './mixins/resize'

export default {
    mixins: [resize],
    props: {
        chartData: {
            type: Array,
            default: function () {
                return []
            }
        },
        className: {
            type: String,
            default: 'chart'
        },
        id: {
            type: String,
            default: 'chart'
        },
        width: {
            type: String,
            default: '200px'
        },
        height: {
            type: String,
            default: '200px'
        }
    },
    data() {
        return {
            chart: null
        }
    },
    mounted() {
        this.initChart()
    },
    beforeDestroy() {
        if (!this.chart) {
            return
        }
        this.chart.dispose()
        this.chart = null
    },
    methods: {
        initChart() {
            this.chart = echarts.init(document.getElementById(this.id))

            const xAxisData = []
            const todayData = []
            const yesterdayData = []
            const d = new Date()
            const hours = d.getHours()
            var maxAxis = 0
            this.chartData.forEach(item => {
                xAxisData.push(item.hours+':00')
                yesterdayData.push(item.yesterday_price)
                if( hours >= parseInt(item.hours)) {
                    todayData.push(item.today_price)
                }
                if(item.today_price > maxAxis) {
                    maxAxis = item.today_price
                }
                if(item.yesterday_price > maxAxis) {
                    maxAxis = item.yesterday_price
                }
            });
            if(!maxAxis > 0) {
                maxAxis = 4
            }
            this.chart.setOption({
                animation: false,
                tooltip: {
                    trigger: 'axis'
                },
                grid: {
                    left: '5%',
                    right: '4%',
                    bottom: '10%'
                },
                legend: {
                    left: '1%',
                    top: '5%',
                    itemWidth: 10,
                    itemHeight: 10,
                    icon: 'rect'
                },
                xAxis: {
                    data: xAxisData,
                    type: 'category',
                    axisLabel: {
                        interval: 2,
						showMaxLabel: true
                    },
                    axisTick: {
                        show: false
                    },
                    boundaryGap: false
                },
                yAxis: {
                    max: maxAxis * 1,
                    type: 'value',
                    splitLine: {
                        show: false
                    },
                    axisLabel: {
                        inside: true,
						verticalAlign: 'bottom',
                        margin: 0
                    },
                    minInterval: maxAxis / 2
                },
                series: [{
                    name: '今日',
                    data: todayData,
                    type: 'line',
                    showSymbol: false,
                    smooth: true,
                    z: 4,
                    itemStyle: {
                        color: '#2062e6'
                    }
                },{
                    name: '昨日',
                    data: yesterdayData,
                    type: 'line',
                    showSymbol: false,
                    smooth: true,
                    itemStyle: {
                        color: '#cecece'
                    }
                }]
            })
        }
    }
}
</script>
