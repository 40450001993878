<template>
	<div :id="id" :class="className" :style="{ height: height, width: width }" />
</template>

<script>
import * as echarts from 'echarts'
import resize from './mixins/resize'

export default {
	mixins: [resize],
	props: {
		seriesData: {
			type: Object,
			default: function () {
				return {}
			}
		},
		seriesName: {
			type: String,
			default: 'chart'
		},
		maxAxis: {
			type: String,
			default: '0'
		},
		formatterY: {
			type: String,
			default: '{value}'
		},
		className: {
			type: String,
			default: 'chart'
		},
		id: {
			type: String,
			default: 'chart'
		},
		width: {
			type: String,
			default: '200px'
		},
		height: {
			type: String,
			default: '200px'
		}
	},
	data() {
		return {
			chart: null
		}
	},
	mounted() {
		this.initChart()
	},
	beforeDestroy() {
		if (!this.chart) {
			return
		}
		this.chart.dispose()
		this.chart = null
	},
	methods: {
		initChart() {
			this.chart = echarts.init(document.getElementById(this.id))
			const xData = []
			const sData = []
			var maxAxis = this.maxAxis * 1
			for(var key in this.seriesData) {
				xData.push(key)
				sData.push(this.seriesData[key])
				if(parseFloat(this.seriesData[key]) > maxAxis) {
					maxAxis = parseFloat(this.seriesData[key])
				}
			}
			if(!maxAxis > 0) {
				maxAxis = 1
			}
			this.chart.setOption({
                animation: false,
                grid: {
                    left: '6%',
                    right: '6%'
                },
                tooltip: {
                    trigger: 'axis'
                },
				xAxis: {
					data: xData,
					type: 'category',
					boundaryGap: false,
                    axisTick: {
                        show: false
                    },
					axisLabel: {
						showMaxLabel: true
					}
				},
				yAxis: {
					max: maxAxis * 1,
					type: 'value',
                    axisLabel: {
                        inside: true,
						verticalAlign: 'bottom',
						formatter: this.formatterY,
                        margin: 0
                    },
					minInterval: maxAxis / 4
				},
				series: [
					{
						data: sData,
						name: this.seriesName,
						type: 'line',
						showSymbol: false,
						smooth: true,
						areaStyle: {}
					}
				]

			})
		}
	}
}
</script>
